








































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AddCustomInvestorGoalWizardPersonalGoalViewModel
  from '@/vue-app/view-models/components/goals-dashboard/add-goals/add-custom-investor-goal-wizard-personal-goal-view-model';

// Domain
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';

@Component({ name: 'AddCustomInvestorGoalWizardPersonalGoal' })
export default class AddCustomInvestorGoalWizardPersonalGoal extends Vue {
  @PropSync('customGoalSelected', { type: Object, required: true })
  custom_investor_goal_dto!: CustomInvestorGoalDto;

  add_custom_investor_goal_personal = Vue.observable(
    new AddCustomInvestorGoalWizardPersonalGoalViewModel(this),
  );

  created() {
    this.add_custom_investor_goal_personal.initialize(this.custom_investor_goal_dto);
  }
}
