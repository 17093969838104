import Vue from 'vue';
import TYPES from '@/types';

// Application
import GetCustomGoalTypesQuery
  from '@/modules/flagship/catalogs/custom-goal-types/application/queries/get-custom-goal-types-query';

// Domain
import {
  CustomGoalTypesEntity,
} from '@/modules/flagship/catalogs/custom-goal-types/domain/entities/custom-goal-types-entity';
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { maxLengthGoalNameRule, requiredRule } from '@/vue-app/utils/form-rules';

export default class AddCustomInvestorGoalWizardPersonalGoalViewModel {
  @Inject(TYPES.GET_CUSTOM_GOAL_TYPES_QUERY)
  private readonly get_custom_goal_type!: GetCustomGoalTypesQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.add-goals.add-custom-investor-goal-wizard-personal-goal';

  readonly view: Vue;

  custom_goal_types: Array<CustomGoalTypesEntity> = [];

  personal_goal = '';

  other_personal_goal = '';

  input_rules = {
    other_goal_name: [requiredRule, (value: string) => maxLengthGoalNameRule(26, value)],
  };

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get is_text_area_disabled() {
    return (this.personal_goal !== this.custom_goal_types[6]?.name);
  }

  get is_continue_btn_disabled() {
    if (this.personal_goal === this.custom_goal_types[6]?.name) {
      return !((this.personal_goal) && (this.other_personal_goal))
        || !(this.other_personal_goal.length < 26);
    }

    return !(this.personal_goal);
  }

  initialize = async (custom_investor_goal_dto: CustomInvestorGoalDto) => {
    this.view.$emit('loadingStep', true);
    this.personal_goal = custom_investor_goal_dto.custom_goal_type!.name!;
    if (custom_investor_goal_dto.custom_goal_type?.name === 'other') {
      this.other_personal_goal = custom_investor_goal_dto.custom_goal_type!.label!;
    }
    await this.getCustomGoalTypes();
    this.view.$emit('loadingStep', false);
  }

  getCustomGoalTypes = async () => {
    try {
      this.custom_goal_types = await this.get_custom_goal_type.execute();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_custom_goal_types'));
    }
  };

  setCustomGoalSelected = () => {
    const custom_goal_value = (this.custom_goal_types.find(
      (item) => item.name === this.personal_goal,
    ));

    if (this.personal_goal === this.custom_goal_types[6]?.name) {
      custom_goal_value!.label = this.other_personal_goal;
    }

    this.view.$emit('setCustomGoalSelected', custom_goal_value);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    this.setCustomGoalSelected();
    this.view.$emit('nextStep');
  }
}
